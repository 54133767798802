import { Text } from "@chakra-ui/layout"
import { useEffect, useState } from "react"
import { TfiLocationPin } from "react-icons/tfi"
import Image from "next/image"
import { maskCep } from "../../utils/maskCep"
import { useCookiesSession } from "../../contexts/cookiesSessionProvider"
import { FormControl, FormLabel, Input } from "@chakra-ui/react"
import { DrawerMobile } from "./DrawerMobile"
import InputMask from "react-input-mask"
import { useForm } from "react-hook-form"
import { baseURL } from "../../services/api"
import { setCookie } from "nookies"
import { useRouter } from "next/router"

export function FreteGratis() {
  const path = useRouter().asPath

  const notShowPages = ["/regulamento/compre-ganhe", "/regulamento/compre-ganhe/"]

  const [isOpen, setIsOpen] = useState(false)
  const { addressClient, setAddressClient } = useCookiesSession()
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm()

  async function getAddressClient(data) {
    try {
      const response = await baseURL.get(`/pedido/busca-endereco-cliente/${data.cep}`)

      if (response.data.length === 0) {
        setError("cep", {
          type: "manual",
          message: "CEP não encontrado",
        })

        return setAddressClient(null)
      }

      const { cep, rua, bairro, cidade, uf, frete_gratis, valida_frete } = response.data[0]

      const cookies = {
        cep,
        rua,
        bairro,
        cidade,
        uf,
        frete_gratis,
        valida_frete,
      }


      setCookie(null, "@innovationbrindes/addressClient:0.0.1", JSON.stringify(cookies), {
        maxAge: 60 * 60 * 24 * 30, // 30 days
        path: "/",
      })

      setAddressClient({
        cep,
        rua,
        bairro,
        cidade,
        uf,
        frete_gratis,
        valida_frete,
      })

      setIsOpen(false)
    } catch (error) {
      setError("cep", {
        type: "manual",
        message: "CEP não encontrado!",
      })

      setAddressClient(null)
    }
  }

  const [addresClientUsage, setAddresClientUsage] = useState(false)

  useEffect(() => {
    if (addressClient) {
      setAddresClientUsage(true)
    }
  }, [addressClient])

  if (notShowPages.includes(path)) {
    return null
  }

  return (
    <>
      <div className={`px-2 bg-[#f5f5f5] hidden md:block font-sans `}>
        <div className="container w-full mx-auto flex items-center gap-8">
          <div className="bg-[#0351F1] text-lg font-bold px-4 rounded-3xl text-white py-1 ml-auto">
            É SÓ AQUI! SUL E SUDESTE TEM FRETE GRÁTIS!
          </div>

          {!addresClientUsage && (
            <div className="relative">
              <div className="flex items-center gap-2 cursor-pointer relative" onClick={() => setIsOpen(true)}>
                <TfiLocationPin size={30} />
                <span className="text-xs uppercase font-medium">Informe seu CEP</span>
              </div>
              {isOpen && (
                <>
                  <div
                    className="fixed inset-0 w-full h-full bg-black opacity-50 z-10"
                    onClick={() => setIsOpen(false)}
                  />
                  <div className="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-50 flex items-center justify-center w-fit h-fit">
                    <div className="w-[298px] h-[281px] border bg-white rounded-2xl relative py-3 px-8">
                      <div className="flex flex-col justify-between">
                        <span>
                          E verifique agora mesmo se o <span className="text-[#80BC00]">FRETE GRÁTIS</span> está
                          disponível para a sua região!
                        </span>
                        <FormControl id="cep" position="relative">
                          <FormLabel htmlFor="cep" mt={4} mb={0} fontSize="14px" color="#414042" fontWeight="normal">
                            Digite seu CEP
                          </FormLabel>
                          <InputMask mask="99999-999" maskChar="_" {...register("cep")}>
                            {({ inputRef, ...rest }) => (
                              <Input
                                id="cepMask"
                                type="text"
                                placeholder="00000-000"
                                borderRadius="0"
                                mt="8px"
                                ref={inputRef}
                                {...rest}
                              />
                            )}
                          </InputMask>
                          {(errors.cep && (
                            <div className="absolute -bottom-5">
                              <Text color="red.400" fontSize="14px">
                                {errors.cep.message}
                              </Text>
                            </div>
                          )) ||
                            null}
                        </FormControl>
                        <button
                          className="bg-[#80BC00] text-white text-lg rounded-lg py-2 px-4 mt-10"
                          onClick={handleSubmit(getAddressClient)}
                        >
                          Confirmar
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {addresClientUsage && (
            <div className="relative">
              <div className="flex items-center gap-1 text-sm cursor-pointer" onClick={() => setIsOpen(true)}>
                <TfiLocationPin size={30} className="text-[#80BC00]" />
                <span>Cep: {maskCep(addressClient.cep)}</span>
                {addressClient?.frete_gratis === "S" && (
                  <span className="bg-[#80BC00] px-2 py-1 text-white rounded-xl uppercase">Tem frete grátis!</span>
                )}
              </div>
              {addressClient?.frete_gratis === "N" && (
                <span className="text-[#80BC00] px-2 py-1 rounded-xl uppercase font-bold text-sm">
                  Não tem frete grátis :(
                </span>
              )}
              {isOpen && (
                <>
                  <div
                    className="fixed inset-0 w-full h-full bg-black opacity-50 z-10"
                    onClick={() => setIsOpen(false)}
                  />
                  <div className="fixed top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-50 flex items-center justify-center w-fit h-fit">
                    <div className="w-[298px] h-[281px] border bg-white rounded-2xl relative py-3 px-8">
                      <div className="flex flex-col justify-between">
                        <span>
                          E verifique agora mesmo se o <span className="text-[#80BC00]">FRETE GRÁTIS</span> está
                          disponível para a sua região!
                        </span>
                        <FormControl id="cep" position="relative">
                          <FormLabel htmlFor="cep" mt={4} mb={0} fontSize="14px" color="#414042" fontWeight="normal">
                            Digite seu CEP
                          </FormLabel>
                          <InputMask mask="99999-999" maskChar="_" {...register("cep")}>
                            {({ inputRef, ...rest }) => (
                              <Input
                                id="cepMask"
                                type="text"
                                placeholder="00000-000"
                                borderRadius="0"
                                mt="8px"
                                ref={inputRef}
                                {...rest}
                              />
                            )}
                          </InputMask>
                          {(errors.cep && (
                            <div className="absolute -bottom-5">
                              <Text color="red.400" fontSize="14px">
                                {errors.cep.message}
                              </Text>
                            </div>
                          )) ||
                            null}
                        </FormControl>
                        <button
                          className="bg-[#80BC00] text-white text-lg rounded-lg py-2 px-4 mt-10"
                          onClick={handleSubmit(getAddressClient)}
                        >
                          Confirmar
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <DrawerMobile />
    </>
  )

  // return (
  //   <>
  //     <div className="block md:hidden">
  //       <DrawerMobile />
  //     </div>
  //     <div className="hidden md:block">
  //       <FreteGratisContainer>
  //         <FreteGratisContent
  //           backgroundColor={addressClient?.frete_gratis === "S" ? "true" : "false"}
  //           onClick={() => setIsOpen(true)}
  //         >
  //           {!addressClient ? (
  //             <>
  //               <TfiLocationPin size={30} />
  //               <Text as="span" m={0} fontSize="14px" onClick={() => setIsOpen(true)}>
  //                 Informe seu CEP
  //               </Text>
  //             </>
  //           ) : addressClient.frete_gratis === "S" ? (
  //             <TemFreteGratis>
  //               <TfiLocationPin size={30} />
  //               <span>Cep: {maskCep(addressClient.cep)}</span>
  //               <h1 onClick={() => setIsOpen(true)}>Tem frete grátis!</h1>
  //             </TemFreteGratis>
  //           ) : (
  //             <SemFreteGratis backgroundColor={addressClient?.frete_gratis === "S" ? "true" : "false"}>
  //               <div className="openPop">
  //                 <TfiLocationPin size={30} />{" "}
  //                 <div className="columnSemFrete">
  //                   <div>
  //                     <span className="not-free">Cep: {maskCep(addressClient.cep)}</span>
  //                     <span className="not-free">não é gratuito :(</span>
  //                   </div>
  //                   <span className="garantimos">
  //                     Mas garantimos ótimas condições de prazo <br /> e valores na sua região.
  //                   </span>
  //                 </div>
  //               </div>
  //             </SemFreteGratis>
  //           )}
  //         </FreteGratisContent>
  //         <ImageContainer>
  //           <div className="flex items-center gap-2">
  //             <span className="text-2xl font-bold uppercase bg-[#80BC00] text-white px-2 rounded-lg">
  //               Brindes com frete grátis
  //             </span>
  //             <span className="text-2xl font-bold text-[#414042] uppercase">Para toda regição sul e sudeste!</span>
  //           </div>
  //         </ImageContainer>
  //         <Popover isOpen={isOpen} onClose={handleClosePopover} setAddressClient={setAddressClient} />
  //       </FreteGratisContainer>
  //     </div>
  //   </>
  // )
}
